
/* google fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

/* css variables */
:root {
  --primary-color: #ECEFF1;
  --heading-color: #fff;
  --btn-primary-bg: #0e2030;
  --btn-secondary-bg: #1c173d;
  --team-card-bg: #1b2352;
  --link-color: #fff;
  --link-active: #28adeb;
  --card-bg: linear-gradient(
    45deg,
    rgb(218, 219, 233) 33%,
    rgba(15, 18, 61, 1) 52%,
    rgba(11, 41, 69, 1) 84%
  );
  --counter-color: linear-gradient(
    90deg,
    #3d3584 30%,
    rgb(0, 0, 0) 55%,
    rgba(35, 93, 173, 1) 75%
  );
  --body-bg: 		#ECEFF1;
  --newsletter-bg: linear-gradient(
    90deg,
    rgb(25, 10, 100) 20%,
    rgb(30, 15, 101) 38%,
    rgb(220, 219, 229) 100%
  );
  --testimonial-bg: #1a0f4f;
  --font-name: "Roboto", sans-serif;
  --small-text-color: rgba(255, 255, 255, 0.774);
  --icon-bg:#151e4f;
}



*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body{
  background: var(--body-bg);
  font-family: var(--font-name);
}
a{
  text-decoration: none;
  color: var(--link-color);
}
h1,h2{
  color: var(--heading-color);
}
section{
  padding: 60px 0px;
}
.container{
  width: 1140px;
  padding: 0px 15px;
  margin: auto;
}
.description{
  color: var(--small-text-color);
  line-height: 30px;
  font-size: .9rem;
}
@media only screen and (max-width:1024px){
  .container{
    width: 100%;
  }
}
@media only screen and (max-width:768px){
  section{
    padding: 40px 0px;
  }
}