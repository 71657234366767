.footer{
    padding: 30px 0px;
    background: #71797E;
}
.footer__wrapper{
    display: flex;
    justify-content: space-between;
}
.footer__logo{
    width: 50%;
}
.footer__quick-links{
    width: 20%;
}
.footer__logo h2{
    color: #0f0f0f;
}
.small__text-description{
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-top: 20px;
}
.small__text{
    font-size: 1.2rem;
    margin-top: 30px;
}

.quick__links{
    list-style: none;
    color: #000000;
}
.quick__links-item{
    margin-bottom: 25px;
}
.quick__links-item a{
    font-size: 0.9rem;
    font-weight: 400;
    cursor: pointer;
}
h3{
    margin-bottom: 10%;
}
.copyright{
    color: #fff;
    margin-top: 40px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
}
@media only screen and (max-width:768px){
    .footer__logo{
        width: 50%;
    }
    .footer__wrapper{
        flex-wrap: wrap;
        column-gap: 1.5rem !important;
        row-gap: 1.5rem;
    }
    .footer__logo h2{
        font-size: 1.5rem;
    }
}
@media only screen and (max-width:576px){
    .footer__quick-links{
        width: 47%;
    }
    .footer__logo{
        width: 47%;
    }
}