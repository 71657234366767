.hero__wrapper{
    display: flex;
    justify-content: space-between;
}
.hero__content{
    width: 50%;
}
.hero__content h2{
    font-size: 1.5rem;
    font-style: italic;
    margin-top: 10%;
    color: #000000;
}
.hero__content p{
    margin-top: 40px;
}
.hero__img{
    max-width: 100%; 
    height: auto;
}
.highlight{
    color: var(--primary-color);
}
@media only screen and (max-width:992px){
    .hero__content h2{
        font-size: 2rem;
        line-height: 50px;
    }
}
@media only screen and (max-width:768px){
    .hero__content h2{
        font-size: 1.5rem;
        line-height: 45px;
    }
}
@media only screen and (max-width:768px){
.hero__content,
.hero__img{
   width: 100%;
    }
    .hero__wrapper{
        flex-direction: column;
    }
}