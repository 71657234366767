.our__projects{
    padding-top: 0;
}
.projects__content{
    text-align: center;
    margin-bottom: 50px;
}
.projects__item{
    width: calc(30% - 25px);
    background: 	#D3D3D3;
    border-radius: 5px;
    margin: 10px;
}
.projects__img{
    width: 100%;
    height: 300px;
    border-radius: 10px;
    background: #D3D3D3;
    overflow: hidden;
    padding-top: 10px;
    
}
.projects__img img{
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
    transform: scale(1.4);
}
.projects__details{
    padding: 20px 15px;
   
}
.projects__details h4{
    color: 	#4e4c4c;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.4rem;
}
.projects__wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -10px;
    align-items: center;
    column-gap: 2rem;
}
