.about__content-desc{
    margin-top: 30px;
    text-align: justify;
}
.highlight{
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    color: black;
    margin-right: 15px;
}
.description h3{
    font-size: 1.2rem;
    color: rgb(0, 0, 0);
    margin-top: 5px;
    margin-bottom: 3%;
    text-indent: 1.5em;
}
@media only screen and (max-width:992px){
    .about__content-desc{
        font-size: 1.5rem;
    }
}
